import React from 'react';
import Paragraph from '../../ui/components/Paragraph';

export default {
    ueberApotique: (
        <i>
            Über <strong>Apotique</strong>
        </i>
    ),
    paragraph1:
        'Die Apotique hat einen ganz besonderen Anspruch. Hier kannst Du herausfinden, welcher das ist.',
    unserAnspruch: <strong>Unser Anspruch</strong>,
    paragraph2: (
        <>
            <Paragraph size="small">
                Wir von Pohl-Boskamp sind uns im Klaren: Damit Wissenstraining
                nachhaltig ist, muss es zu Dir passen – zu Deinem Wissensstand,
                Deinen Interessen und Deinen Zielen. Es muss up-to-date und mit
                Deinen Bedürfnissen kombinierbar sein.
            </Paragraph>
            <Paragraph size="small">
                Genau auf dieser Idee gründet die Mission von Apotique:
                Gemeinsam möchten wir Dir maßgeschneidertes Apothekenwissen
                bieten – mit unserem wachsenden Markensortiment, aus dem Du
                Deine Lern-Staples passgenau zusammenstellen kannst. Das Beste:
                Unsere Lernplattform entwickelt sich stetig weiter, damit Deine
                Wissens-Tour weiterhin spannend bleibt.
            </Paragraph>
            <Paragraph size="small">
                Ob Produktwissen, Verkaufsstilberatung oder Intérieur-Coaching:
                Bei uns kannst Du Dir Deine Apotique genau nach Deinem Geschmack
                einrichten. Wenn Du Tipps, Ideen oder Feedback hast, kannst Du
                uns gerne über unser Kontaktformular schreiben – Deine Hinweise
                inspirieren uns.
            </Paragraph>
            <Paragraph size="small">
                Also dann: Stöbere einfach durch unsere Regale und konstruiere
                Dir Dein individuelles Lernkonzept. Schon bald wirst Du Dein neu
                erworbenes Wissen stilsicher in Szene setzen können!
            </Paragraph>
        </>
    ),
};
