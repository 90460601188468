import React from 'react';
import Layout from '../ui/layout/Layout';
import {Section, Container} from '../ui/layout/Grid';
import Headline from '../ui/components/Headline';
import lang from '../lang/pages/anspruch.lang';
import Paragraph from '../ui/components/Paragraph';
import {ReactComponent as AnspruchSvg} from '../assets/images/anspruch/img-intro--anspruch.svg';

const Anspruch = () => {
    return (
        <Layout>
            <Section>
                <Container width="narrow" className="text-center">
                    <Headline type="h1">{lang.ueberApotique}</Headline>
                    <Paragraph>{lang.paragraph1}</Paragraph>
                </Container>
                <Container width="narrow" className="text-center">
                    <AnspruchSvg />
                    <Headline type="h4">{lang.unserAnspruch}</Headline>
                    {lang.paragraph2}
                </Container>
            </Section>
        </Layout>
    );
};

export default Anspruch;
